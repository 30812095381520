import React from "react";

export default function BackIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <g
        id="Group_1225"
        data-name="Group 1225"
        transform="translate(-331.329 -63.5)"
      >
        <path
          id="Path_1595"
          data-name="Path 1595"
          d="M12,0A12,12,0,1,1,0,12,12.193,12.193,0,0,1,2.026,5.325,11.857,11.857,0,0,1,12,0Z"
          transform="translate(331.329 63.5)"
          fill="none"
        />
        <path
          id="Path_2841"
          data-name="Path 2841"
          d="M3016.428,71l-5.714,4.857,5.714,4.857"
          transform="translate(-2671)"
          fill="none"
          stroke="#fff"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
}
